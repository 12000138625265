export const CLASS_MOBILE_ONLY = 'd-md-none';
export const CLASS_DESKTOP_ONLY = 'd-none d-md-block';

export const SLIDER_HEIGHT = 600;
export const SLIDER_WIDTH = 600 * 2.5;

export const HEIGHT_HEADER = 68;
export const FLOATING_NAVIGATION_DELTA_STEP = 60;
export const HEIGHT_PRIMARY_NAVIGATION = 52;
export const HEIGHT_BUTTON_NAVIGATION = 44;
export const HEIGHT_SECONDARY_NAIVGATION = 56;
export const HEIGHT_NAVIGATION = HEIGHT_PRIMARY_NAVIGATION + HEIGHT_SECONDARY_NAIVGATION;

export const FLOATING_NAVIGATION_Z_INDEX = 1020;

export const HUB_HEADER_ID_SELECTOR = 'app-hub-header';
export const HUB_FOOTER_ID_SELECTOR = 'app-hub-footer';
export const HUB_HEADER_HEIGHT = 68;

export const IMAGE_BIG_SIZE = 500;
export const IMAGE_MEDIUM_SIZE = 300;
export const IMAGE_SMALL_SIZE = 120;

export const HEADER_BUTTON_SIZE = 44;
