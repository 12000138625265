import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-sticky-el';
import { useNavigationPosition } from '../../hooks/useNavigationPosition';
import { FLOATING_NAVIGATION_Z_INDEX, HEIGHT_HEADER, FLOATING_NAVIGATION_DELTA_STEP } from '../../const/responsive';
import { isModalOpened } from '../../helpers/modal';

function FloatingNavigation({ children }) {
  const { topOffset, setTopOffset } = useNavigationPosition();

  const [position, setPosition] = useState(topOffset);

  useEffect(() => {
    const handleScroll = () => {
      const moving = window.pageYOffset;

      if (Math.abs(position - moving) < FLOATING_NAVIGATION_DELTA_STEP) {
        return;
      }

      if (isModalOpened()) {
        return;
      }

      if (position > moving) {
        setTopOffset(0);
      } else {
        setTopOffset(-HEIGHT_HEADER);
      }
      setPosition(moving);
    };
    window.addEventListener('scroll', handleScroll);
    return (() => {
      window.removeEventListener('scroll', handleScroll);
    });
  });
  return (
    <Sticky stickyStyle={{ zIndex: FLOATING_NAVIGATION_Z_INDEX, top: `${topOffset}px`, transition: 'top 0.3s ease-out' }}>
      { children }
    </Sticky>
  );
}

FloatingNavigation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default FloatingNavigation;
