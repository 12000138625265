import React from 'react';
import Link from 'next/link';
import { Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { SidebarCloseIcon } from '../Images';
import l from '../../helpers/lang';
import SidebarEvent from './SidebarEvent';
import { pageShape } from '../../helpers/propTypes';

function Sidebar({ visible = false, pages = [] }) {
  const handleCloseClick = () => {
    SidebarEvent.triggetVisbile(false);
  };

  return (
    <Offcanvas show={visible} onHide={() => handleCloseClick()} placement="end" className="bg-body">
      <Offcanvas.Header className="d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-secondary p-0 d-flex align-items-center justify-content-center"
          style={{ minWidth: 44, minHeight: 44 }}
          onClick={handleCloseClick}
        >
          <SidebarCloseIcon />
        </button>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ul className="navbar-nav flex-column">
          {pages.map((item) => (
            <li className="nav-item mb-2" key={item.static_page_id}>
              <Link
                href={item.full_url}
                className="nav-link text-body border-bottom"
                aria-current="page"
                onClick={handleCloseClick}
              >
                {item.title}
              </Link>
            </li>
          ))}
          <li className="nav-item">
            <Link
              href="/?modal=contacts"
              as="/contacts"
              className="nav-link text-body border-bottom"
              aria-current="page"
              onClick={handleCloseClick}
              scroll={false}
            >
              {l('delivery.navbar.contacts')}
            </Link>
          </li>
        </ul>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

Sidebar.propTypes = {
  visible: PropTypes.bool,
  pages: PropTypes.arrayOf(pageShape),
};

Sidebar.defaultProps = {
  visible: false,
  pages: [],
};

export default Sidebar;
