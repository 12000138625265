import React from 'react';
import Link from 'next/link';
import { HeaderArrowLeftIcon } from '../Images';
import { HEADER_BUTTON_SIZE } from '../../const/responsive';

export default function BackButton(props) {
  return (
    <Link
      href="/"
      style={{ minWidth: HEADER_BUTTON_SIZE, minHeight: HEADER_BUTTON_SIZE }}
      className="d-flex align-items-center justify-content-center text-body"
      data-testid="back_btn"
      {...props}
    >
      <HeaderArrowLeftIcon />
    </Link>
  );
}
