import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Image from 'next/image';
import classNames from 'classnames';
import { useSettings } from '../Context';

function Logo({
  src, title, width, heigth, stretched = false,
}) {
  const [windowWidth, setWindowWidth] = useState(0);
  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);
  let logoWidth = width;
  let logoHeight = heigth;

  if (!width || !heigth) {
    ({ logoHeight, logoWidth } = useSettings());
  }

  if (logoHeight && logoWidth) {
    const ratio = logoWidth / logoHeight;
    logoHeight = 44;
    logoWidth = logoHeight * ratio;

    if (logoWidth > 200) {
      logoWidth = 200;
      logoHeight = logoWidth / ratio;
    }

    if (logoWidth > 140 && windowWidth <= 360) {
      logoWidth = 140;
      logoHeight = logoWidth / ratio;
    }
  }

  return (
    <Link
      href="/"
      className={classNames(
        'text-decoration-none text-body d-flex flex-shrink-0',
        { 'w-100': stretched && !src },
      )}
    >
      {src && <Image src={src} width={logoWidth} height={logoHeight} alt={title} />}
      {!src && <span className="fs-4 text-truncate">{title}</span>}
    </Link>
  );
}

Logo.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number,
  heigth: PropTypes.number,
  stretched: PropTypes.bool,
};

Logo.defaultProps = {
  src: '',
  title: '',
  width: undefined,
  heigth: undefined,
  stretched: false,
};

export default Logo;
