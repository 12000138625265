import React from 'react';
import { HeaderSearchIcon } from '../Images';
import { HEADER_BUTTON_SIZE } from '../../const/responsive';

function SearchButton(props) {
  return (
    <button
      type="button"
      data-testid="open_search_btn"
      className="btn border-0 p-0"
      style={{ width: HEADER_BUTTON_SIZE, height: HEADER_BUTTON_SIZE }}
      {...props}
    >
      <HeaderSearchIcon />
    </button>
  );
}

export default SearchButton;
