import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { CLASS_DESKTOP_ONLY, CLASS_MOBILE_ONLY } from '../../const/responsive';
import { navbarProps } from '../../helpers/propTypes';
import { useSettings } from '../Context';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import Sidebar from './Sidebar';
import SidebarEvent from './SidebarEvent';

function Header({ menu, backUrl = '', withSearch = false }) {
  const { phone, logo, shopName } = useSettings();
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  useEffect(() => SidebarEvent.onVisibleChanged(({ detail }) => {
    setSidebarVisible(detail);
  }), []);

  return (
    <div className="bg-body">
      <div className={CLASS_DESKTOP_ONLY}>
        <HeaderDesktop logo={logo} shopName={shopName} phone={phone} withSearch={withSearch} />
      </div>
      <div className={CLASS_MOBILE_ONLY}>
        <HeaderMobile logo={logo} shopName={shopName} backUrl={backUrl} withSearch={withSearch} />
      </div>

      <Sidebar pages={menu.pages} visible={isSidebarVisible} />
    </div>
  );
}

Header.propTypes = {
  menu: navbarProps.isRequired,
  backUrl: PropTypes.string,
  withSearch: PropTypes.bool,
};

export default Header;
