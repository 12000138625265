import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import _ from 'underscore';
import PropTypes from 'prop-types';

import { HeaderSearchIcon, HeaderSearhClearIcon } from '../Images';
import SearchEvent from './SearchEvent';
import l from '../../helpers/lang';
import style from './Search.module.scss';

/* eslint-disable jsx-a11y/no-autofocus */
function Search({ autoFocus = false }) {
  const input = useRef(null);
  const [isFocused, setFocused] = useState(false);

  function setValue(value) {
    if (input) {
      input.current.value = value;
    }
  }

  function triggerChanges(query) {
    SearchEvent.triggerSearch(query);
  }

  function handleChange(query) {
    setValue(query);
    _.debounce(triggerChanges(query), 500);
  }

  function handleClearClick() {
    setValue('');
    triggerChanges('');
    setFocused(true);
    input?.current?.focus();
  }

  function handleFocus() {
    setFocused(true);
    input?.current?.focus();
  }

  function handleBlur() {
    setFocused(false);
  }

  function handleKeyDown(e) {
    if (e.key === 'Escape') {
      handleChange('');
    }
  }

  useEffect(() => SearchEvent.onPure(handleClearClick), []);

  const isShowBg = isFocused || input?.current?.value?.length > 0;

  return (
    <div
      className={classNames(
        'd-flex align-items-center border rounded-2 w-100 border-transparent',
        { 'bg-secondary': isShowBg },
        style.wrapper,
      )}
    >
      <button
        type="button"
        className={classNames('btn p-0 d-flex align-items-center justify-content-center', style.btn)}
        onClick={handleFocus}
      >
        <HeaderSearchIcon className="text-light-grey" />
      </button>

      <input
        data-testid="search_input"
        className={classNames(
          'border border-0 rounded-2 p-0 w-100',
          { 'bg-secondary': isShowBg },
          { 'bg-transparent': !isShowBg },
          style.input,
        )}
        onBlur={handleBlur}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={handleFocus}
        placeholder={l('delivery.global.search')}
        ref={input}
        type="text"
        autoFocus={autoFocus}
        onKeyDown={handleKeyDown}
      />

      {isShowBg && (
        <button
          type="button"
          className={classNames('btn p-0 d-flex align-items-center justify-content-center', style.btn)}
          onClick={handleClearClick}
        >
          <HeaderSearhClearIcon />
        </button>
      )}
    </div>
  );
}

Search.propTypes = {
  autoFocus: PropTypes.bool,
};

Search.defaultProps = {
  autoFocus: false,
};

export default Search;
