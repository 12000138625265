import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import Search from '../search/Search';
import SidebarButton from './SidebarButton';
import Logo from './Logo';

function HeaderDesktop({
  phone = '', logo = '', shopName = '', withSearch = false,
}) {
  return (
    <nav className="container px-5 py-3 d-flex justify-content-between">
      {/* left side */}
      <div className="d-flex align-items-center">
        <Logo src={logo} title={shopName} />
        <span className="me-7" />
        { withSearch && <Search /> }
      </div>

      {/* right side */}
      <div className="d-flex align-items-center">
        {Boolean(phone) && (
          <Link className="text-body text-decoration-none me-6" href={`tel:${phone}`}>
            {phone}
          </Link>
        )}
        <SidebarButton />
      </div>
    </nav>
  );
}

HeaderDesktop.propTypes = {
  phone: PropTypes.string,
  logo: PropTypes.string,
  shopName: PropTypes.string,
  withSearch: PropTypes.bool,
};

export default HeaderDesktop;
