function onVisibleChanged(cb) {
  window.addEventListener('sidebar:state', cb);

  return () => {
    window.removeEventListener('sidebar:state', cb);
  };
}

function triggetVisbile(value) {
  window.dispatchEvent(new CustomEvent('sidebar:state', { detail: value }));
}

export default {
  onVisibleChanged,
  triggetVisbile,
};
