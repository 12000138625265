import React from 'react';
import { HeaderMenuIcon } from '../Images';
import SidebarEvent from './SidebarEvent';
import { HEADER_BUTTON_SIZE } from '../../const/responsive';

export default function SidebarButton() {
  function handleClick() {
    SidebarEvent.triggetVisbile(true);
  }

  return (
    <button
      type="button"
      className="btn btn-outline-secondary p-0"
      style={{ minWidth: HEADER_BUTTON_SIZE, minHeight: HEADER_BUTTON_SIZE }}
      onClick={handleClick}
    >
      <HeaderMenuIcon />
    </button>
  );
}
