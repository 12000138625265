function onSearch(cb) {
  window.addEventListener('header:search', cb);

  return () => {
    window.removeEventListener('header:search', cb);
  };
}

function onPure(cb) {
  window.addEventListener('header:pure', cb);

  return () => {
    window.removeEventListener('header:pure', cb);
  };
}

function triggerSearch(value) {
  window.dispatchEvent(new CustomEvent('header:search', { detail: value }));
}

function triggerPure() {
  window.dispatchEvent(new CustomEvent('header:pure'));
}

export default {
  onSearch,
  onPure,
  triggerSearch,
  triggerPure,
};
