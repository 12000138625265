import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BackButton from './BackButton';
import Search from '../search/Search';
import SearchButton from '../search/SearchButton';
import SidebarButton from './SidebarButton';
import Logo from './Logo';
import SearchEvent from '../search/SearchEvent';
import { HEADER_BUTTON_SIZE } from '../../const/responsive';

function HeaderMobile({
  logo, shopName, backUrl = '', withSearch,
}) {
  const [isSearchOpened, setSearchOpened] = useState(false);

  const toggleeSearch = () => setSearchOpened((prevValue) => !prevValue);

  function handleBackClick(e) {
    e.preventDefault();
    SearchEvent.triggerPure();
  }

  useEffect(() => SearchEvent.onPure(() => {
    setSearchOpened(false);
  }));

  return (
    <div className="container py-3">
      {!isSearchOpened && (
        <div className="d-flex justify-content-between">
          {backUrl.length > 0 && (
            <div className="col-auto d-flex align-items-center pe-0">
              <BackButton />
            </div>
          )}
          <div
            className={classNames('col d-flex align-items-center pe-0', {
              'justify-content-center': backUrl.length > 0,
            })}
            style={{ width: `calc(100% - ${HEADER_BUTTON_SIZE * 3}px)` }}
          >
            <Logo src={logo} title={shopName} stretched />
          </div>

          <div className="col-auto d-flex align-items-center justify-content-end ps-0">
            {withSearch && <SearchButton onClick={toggleeSearch} />}
            <SidebarButton />
          </div>
        </div>
      )}

      {isSearchOpened && (
        <div className="d-flex">
          <BackButton onClick={(e) => handleBackClick(e)} />
          <Search autoFocus />
        </div>
      )}
    </div>
  );
}

HeaderMobile.propTypes = {
  logo: PropTypes.string,
  shopName: PropTypes.string,
  backUrl: PropTypes.string,
  withSearch: PropTypes.bool,
};

HeaderMobile.defaultProps = {
  logo: '',
  shopName: '',
  backUrl: '',
  withSearch: false,
};

export default HeaderMobile;
